<template>
  <div class="mt-5">

    <Loading :open="true" :msg="''" />

  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import StarRating from "vue-star-rating";
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";

import Loading from '../components/Loading.vue'

export default {
  components: {
    StarRating,
    Hooper,
    Slide,
    HooperNavigation,
    Loading
  },
  data() {
    return {
      hooperSettings: {
        infiniteScroll: true,
        centerMode: false,
        autoPlay: false,
        playSpeed: 3000,
        breakpoints: {
          1024: {
            itemsToShow: 3,
          },
          768: {
            itemsToShow: 2,
          },
          425: {
            itemsToShow: 1,
          },
        },
      },
    };
  },
  mounted() {
    
      this.getCompanies().then((response) => {

        const subTenant = window.location.hostname.split('.')[0];
        response.data.data.forEach(tenant => {
          if (subTenant === tenant.sub_domain) {
            console.log('aa', tenant.sub_domain);
          this.$router.push({
              name: 'products',
              params: { companyUrl: tenant.url, uuid: tenant.uuid, sub_domain: tenant.sub_domain },
            });
          }
        });
      })
        .catch((error) => {
          this.$swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
            icon: "error",
            title: "Erro ao carregar as lojas",
          });
        });
    



  },
  methods: {
    ...mapActions(["getCompanies"]),
  },
  computed: {
    ...mapState({
      companies: (state) => state.companies.items,
    }),
  },
};
</script>
